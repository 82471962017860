const Login = () => import (/* webpackChunkName: "common-group" */ '../views/Admin/account/Login.vue');
const Dashboard = () => import (/* webpackChunkName: "common-group" */'../views/Admin/Dashboard/Dashboard.vue');
const Profile = () => import (/* webpackChunkName: "common-group" */'../views/Admin/Profile/Profile.vue');
const Security = () => import (/* webpackChunkName: "common-group" */'../views/Admin/Profile/Security.vue');
const NotFound = () => import (/* webpackChunkName: "common-group" */'../components/404.vue');

// Stores
const StoreIndex = () => import (/* webpackChunkName: "store-group" */'../views/Admin/Stores/StoreIndex.vue');
const StoreForm = () => import (/* webpackChunkName: "store-group" */'../views/Admin/Stores/StoreForm.vue');
const StoreView = () => import (/* webpackChunkName: "store-group" */'../views/Admin/Stores/StoreView.vue');

// Category
const CategoryIndex = () => import (/* webpackChunkName: "category-group" */'../views/Admin/Category/CategoryIndex.vue');
const CategoryForm = () => import (/* webpackChunkName: "category-group" */'../views/Admin/Category/CategoryForm.vue');

// Product
const ProductIndex = () => import (/* webpackChunkName: "category-group" */'../views/Admin/Product/ProductIndex.vue');
const ProductForm = () => import (/* webpackChunkName: "category-group" */'../views/Admin/Product/ProductForm.vue');
const ProductView = () => import (/* webpackChunkName: "category-group" */'../views/Admin/Product/ProductView.vue');

// Campaign
const CampaignIndex = () => import (/* webpackChunkName: "campaign-group" */'../views/Admin/Campaign/CampaignIndex.vue');
const CampaignForm = () => import (/* webpackChunkName: "campaign-group" */'../views/Admin/Campaign/CampaignForm.vue');
const CampaignView = () => import (/* webpackChunkName: "campaign-group" */'../views/Admin/Campaign/CampaignView.vue');

// Post
const PostIndex = () => import (/* webpackChunkName: "post-group" */'../views/Admin/Posts/PostIndex.vue');
const PostForm = () => import (/* webpackChunkName: "post-group" */'../views/Admin/Posts/PostForm.vue');

// User
const UserIndex = () => import (/* webpackChunkName: "user-group" */'../views/Admin/Users/Staff/StaffIndex.vue');
const UserForm = () => import (/* webpackChunkName: "user-group" */'../views/Admin/Users/Staff/StaffForm.vue');
// const UserView = () => import (/* webpackChunkName: "user-group" */'../views/Admin/Users/Staff/UserForm.vue');

// Vendor
const VendorIndex = () => import (/* webpackChunkName: "user-group" */'../views/Admin/Users/Vendor/VendorIndex.vue');

// Consumer
const ConsumerIndex = () => import (/* webpackChunkName: "user-group" */'../views/Admin/Users/Consumer/ConsumerIndex.vue');

// Roles
const RoleIndex = () => import (/* webpackChunkName: "user-group" */'../views/Admin/Role/RoleIndex.vue');
const RoleForm = () => import (/* webpackChunkName: "user-group" */'../views/Admin/Role/RoleForm.vue');

// Post
const FaqIndex = () => import (/* webpackChunkName: "faq-group" */'../views/Admin/Faq/FaqIndex.vue');
const FaqForm = () => import (/* webpackChunkName: "faq-group" */'../views/Admin/Faq/FaqForm.vue');

export default [
    {
        path: '/admin/login',
        name: 'admin-login',
        component: Login,
        meta: {
            requiresVisitor: false,
            requiresAdmin: true,
        }
    },
    {
        path: '/admin/dashboard',
        name: 'admin-dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard', rule: true,
            requiresAuth: true,
            requiresAdmin: true,
        }
    },
    {
        path: '/admin/profile',
        name: 'admin-profile',
        component: Profile,
        meta: {
            title: 'Profile', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/security',
        name: 'admin-security',
        component: Security,
        meta: {
            title: 'Security', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },

    //Category Management
    {
        path: '/admin/categories',
        name: 'admin-category-index',
        params: true,
        component: CategoryIndex,
        meta: {
            title: 'Categories', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/categories/create',
        name: 'admin-category-create',
        params: true,
        component: CategoryForm,
        meta: {
            title: 'Create Category', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/categories/:uid/edit',
        name: 'admin-category-edit',
        params: true,
        component: CategoryForm,
        meta: {
            title: 'Edit Category', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },

    //Post Management
    {
        path: '/admin/posts',
        name: 'admin-post-index',
        params: true,
        component: PostIndex,
        meta: {
            title: 'Posts', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/posts/create',
        name: 'admin-post-create',
        params: true,
        component: PostForm,
        meta: {
            title: 'Create Post', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/posts/:uid/edit',
        name: 'admin-post-edit',
        params: true,
        component: PostForm,
        meta: {
            title: 'Edit Post', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },

    //FAQ Management
    {
        path: '/admin/faq',
        name: 'admin-faq-index',
        params: true,
        component: FaqIndex,
        meta: {
            title: 'FAQ', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/faq/create',
        name: 'admin-faq-create',
        params: true,
        component: FaqForm,
        meta: {
            title: 'Create FAQ', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/faq/:uid/edit',
        name: 'admin-faq-edit',
        params: true,
        component: FaqForm,
        meta: {
            title: 'Edit FAQ', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },

    //Product Management
    {
        path: '/admin/products',
        name: 'admin-product-index',
        params: true,
        component: ProductIndex,
        meta: {
            title: 'Products', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/products/create',
        name: 'admin-product-create',
        params: true,
        component: ProductForm,
        meta: {
            title: 'Create Product', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/products/:uid/edit',
        name: 'admin-product-edit',
        params: true,
        component: ProductForm,
        meta: {
            title: 'Edit Product', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/products/:uid',
        name: 'admin-product-view',
        params: true,
        component: ProductView,
        meta: {
            title: 'View Product', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },

    //Store Management
    {
        path: '/admin/stores',
        name: 'admin-store-index',
        params: true,
        component: StoreIndex,
        meta: {
            title: 'Stores', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/stores/create',
        name: 'admin-store-create',
        params: true,
        component: StoreForm,
        meta: {
            title: 'Create Store', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/stores/:uid/edit',
        name: 'admin-store-edit',
        params: true,
        component: StoreForm,
        meta: {
            title: 'Edit Store', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/stores/:uid',
        name: 'admin-store-view',
        params: true,
        component: StoreView,
        meta: {
            title: 'View Store', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },

    //Campaign Management
    {
        path: '/admin/campaigns',
        name: 'admin-campaign-index',
        params: true,
        component: CampaignIndex,
        meta: {
            title: 'Campaigns', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/campaigns/create',
        name: 'admin-campaign-create',
        params: true,
        component: CampaignForm,
        meta: {
            title: 'Create Campaign', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/campaigns/:uid/edit',
        name: 'admin-campaign-edit',
        params: true,
        component: CampaignForm,
        meta: {
            title: 'Edit Campaign', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/campaigns/:uid',
        name: 'admin-campaign-view',
        params: true,
        component: CampaignView,
        meta: {
            title: 'View Campaign', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },

    //User Management
    {
        path: '/admin/users',
        name: 'admin-user-index',
        params: true,
        component: UserIndex,
        meta: {
            title: 'Users', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/users/create',
        name: 'admin-user-create',
        params: true,
        component: UserForm,
        meta: {
            title: 'Create User', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/users/:uid/edit',
        name: 'admin-user-edit',
        params: true,
        component: UserForm,
        meta: {
            title: 'Edit User', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },

    //Vendor Management
    {
        path: '/admin/vendors',
        name: 'admin-vendor-index',
        params: true,
        component: VendorIndex,
        meta: {
            title: 'Users', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },

    //Consumer Management
    {
        path: '/admin/consumers',
        name: 'admin-consumers-index',
        params: true,
        component: ConsumerIndex,
        meta: {
            title: 'Consumers', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },

    //Role Management
    {
        path: '/admin/roles',
        name: 'admin-role-index',
        params: true,
        component: RoleIndex,
        meta: {
            title: 'Roles', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/roles/create',
        name: 'admin-role-create',
        params: true,
        component: RoleForm,
        meta: {
            title: 'Create Role', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
    {
        path: '/admin/roles/:id/edit',
        name: 'admin-role-edit',
        params: true,
        component: RoleForm,
        meta: {
            title: 'Edit Role', rule: true,
            requiresAuth: true, requiresAdmin: true,
        }
    },
];
