import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    createProfile(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`profile/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeProfile(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`profile`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showProfile(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`profile/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editProfile(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`profile/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateProfile(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`profile/${data.uid}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteProfile(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`profile/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
