import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getProducts(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`product`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createProduct(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`product/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeProduct(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`product`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showProduct(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`product/${data.uid}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editProduct(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`product/${data.uid}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateProduct(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`product/${data.uid}?_method=PUT`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteProduct(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`product/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
