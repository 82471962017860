import axios from "axios";

const state = {
    totalUnreadChat: 0
}
const mutations = {
    totalUnreadChat(state, payload) {
        state.totalUnreadChat = payload
    }
}
const getters = {
    totalUnreadChat: state => state.totalUnreadChat,
}

const actions = {
    getMessages(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('chat-message', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeMessage(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post('chat-message', data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeChatMessage(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post('chat-message', data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showMessage(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`chat-message/${params.chatId}`, {params: {contact: params.contact}})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showSingleMessage(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`chat-message-single/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
