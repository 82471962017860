import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getWishlists(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`wishlist`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createWishlist(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`wishlist/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeWishlist(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`wishlist`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editWishlist(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`wishlist/${data.uid}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateWishlist(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`wishlist/${data.uid}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteWishlist(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`wishlist/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
