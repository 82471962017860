import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getCampaigns(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`campaign`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createCampaign(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`campaign/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeCampaign(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`campaign`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showCampaign(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`campaign/${data.uid}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editCampaign(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`campaign/${data.uid}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateCampaign(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`campaign/${data.uid}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteCampaign(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`campaign/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateCampaignStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`campaign-status/${data.uid}?_method=PUT`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
