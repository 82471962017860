import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

const axios = require('axios');

axios.defaults.baseURL = '/api/v1/';
const maxRetryAttempts = 3;
const retryDelay = 1000;

// axios.interceptors.response.use(
//     response => {
//         return response;
//     },
//     error => {
//         const {config, response} = error;
//
//         // Check if the error is due to a network issue or a 5xx server error
//         if (!response || (response.status >= 400 && response.status < 600)) {
//             let retryCount = config.retryCount || 0;
//
//             if (retryCount < maxRetryAttempts) {
//                 // Increase the retry count
//                 retryCount++;
//
//                 // Set a delay before retrying
//                 return new Promise(resolve => {
//                     setTimeout(() => resolve(axios(config)), retryDelay);
//                 });
//             }
//         }
//
//         // If the error is not retried or it's not a network/server error, handle it accordingly
//         // if (response) {
//         //     // Handle specific error codes or conditions
//         //     if (response.status === 401) {
//         //         // Handle unauthorized error
//         //     } else {
//         //         // Handle other error cases
//         //     }
//         // } else {
//         //     // Handle network error or timeout
//         // }
//
//         return Promise.reject(error);
//     }
// );

Vue.use(Vuex);
export const store = new Vuex.Store({
    modules,
});
