import axios from "axios";

const state = {
    token: localStorage.getItem('token'),
    login_activity_id: localStorage.getItem('login_activity_id'),
    accessToken: localStorage.getItem('accessToken'),
}

const mutations = {
    token(state, token) {
        state.token = token
    },
    login_activity_id(state, token) {
        state.login_activity_id = token
    },
    accessToken(state, token) {
        state.accessToken = token
    },
}
const getters = {
    token(state) {
        return state.accessToken
    },
    login_activity_id(state) {
        return state.login_activity_id
    },
    accessToken(state) {
        return state.accessToken
    },
}

const actions = {
    login(context, credentials) {
        return new Promise(async (resolve, reject) => {
            await axios.post('login', {
                email: credentials.email,
                password: credentials.password,
            })
                .then(response => {
                    const {token, user} = response.data
                    const login_activity_id = response.data.login_activity_id
                    localStorage.setItem('token', token)
                    localStorage.setItem('accessToken', token)
                    localStorage.setItem('login_activity_id', login_activity_id)
                    localStorage.setItem('user', JSON.stringify(user))
                    context.commit('token', token)
                    context.commit('accessToken', token)
                    context.commit('login_activity_id', login_activity_id)
                    context.commit('user', user)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    register(context, credentials) {
        return new Promise((resolve, reject) => {
            axios.post('register', credentials)
                .then(response => {
                    const {token, user} = response.data
                    const login_activity_id = response.data.login_activity_id
                    localStorage.setItem('accessToken', token)
                    localStorage.setItem('login_activity_id', login_activity_id)
                    localStorage.setItem('user', JSON.stringify(user))
                    context.commit('accessToken', token)
                    context.commit('login_activity_id', login_activity_id)
                    context.commit('user', user)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resendEmailVerification(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post('resend-verification-email')
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    logout(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post('logout', data)
                    .then(response => {
                        localStorage.removeItem('accessToken')
                        localStorage.removeItem('token')
                        localStorage.removeItem('user')
                        localStorage.removeItem('storeRegister')
                        localStorage.removeItem('resendCountdown')
                        context.commit('accessToken', null);
                        context.commit('user', null);
                        context.commit('totalUnreadChat', 0)
                        context.commit('token', null)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    sendPasswordResetLink(context, data) {
        return new Promise((resolve, reject) => {
            axios.post('send-password-reset-email', data)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetPassword(context, data) {
        return new Promise((resolve, reject) => {
            axios.post('password-reset', data)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    verifyEmail(context, data) {
        return new Promise((resolve, reject) => {
            axios.post('verify-email', data)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
