import Vue from "vue";
import {store} from "../store/state";
import VueRouter from 'vue-router'

import normalRoutes from './normalRoutes.js'
import adminRoutes from './adminRoutes.js'

Vue.use(VueRouter)

const routes = [
    ...normalRoutes,
    ...adminRoutes,
]

function configureRouter() {
    const router = new VueRouter({
        routes,
        mode: 'history',
        linkActiveClass: 'mm-active',
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition
            } else {
                return {x: 0, y: 0}
            }
        }
    });

    router.beforeEach((to, from, next) => {
        if (to.matched.some(record => record.meta.requiresAuth && !record.meta.requiresVerified && !record.meta.requiresAdmin)) {
            if (store.getters.token) {
                next()
            } else {
                next({
                    name: 'login',
                    query: {to: to ? to.path : null}
                })
            }
        } else if (to.matched.some(record => record.meta.requiresAuth && !record.meta.requiresVerified && record.meta.requiresAdmin)) {
            if (store.getters.token) {
                next()
            } else {
                next({
                    name: 'admin-login',
                    query: {to: to ? to.path : null}
                })
            }
        } else if (to.matched.some(record => record.meta.requiresAuth && record.meta.requiresVerified)) {
            if (store.getters.token && store.getters.user.isVerified) {
                next()
            } else {
                next({
                    name: 'email-verify',
                    query: {to: to ? to.path : null}
                })
            }
        } else if (to.matched.some(record => record.meta.requiresVisitor)) {
            if (store.getters.token) {
                next({name: 'welcome'})
            } else {
                next()
            }
        } else if (to.fullPath === from.fullPath) {
            console.warn('Redundant navigation detected:', to.fullPath);
            next()
        } else if (to.name === 'login') {
            if (store.getters.token) {
                const type = store.getters.user.type
                if (type === 'Consumer') {
                    next({name: 'account-dashboard'})
                } else if (type === 'Vendor') {
                    next({name: 'vendor-dashboard'})
                } else {
                    next()
                }
            }
        } else {
            next()
        }
        document.title = to.meta.title ? to.meta.title : "e-Saugat. Your local marketplace"
        next()
    })

    return router;
}

export default configureRouter;
