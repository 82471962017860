import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getVendorProducts(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`vendor-product`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createVendorProduct(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`vendor-product/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeVendorProduct(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`vendor-product`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showVendorProduct(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`vendor-product/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editVendorProduct(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`vendor-product/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateVendorProduct(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`vendor-product/${data.uid}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteVendorProduct(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`vendor-product/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
