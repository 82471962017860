import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getDashboardReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`dashboard`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getVendorDashboard(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`vendor-dashboard`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getConsumerDashboard(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`account-dashboard`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
