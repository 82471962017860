/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
import Vue from "vue";
import {BootstrapVue} from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import i18n from "./i18n";
import VueApexCharts from 'vue-apexcharts'
import configureRouter from './routes/routes';
import {store} from './store/state';
import Vuex from 'vuex'

import VueAWN from "vue-awesome-notifications";
import 'vue-awesome-notifications/dist/styles/style.css';
import Layouts from "./mixins/layouts.mixin";

window.Vue = Vue;
const axios = require('axios');

let options = {position: "top-right"}
Vue.use(VueAWN, options)

require('./nepali.datepicker.v3.6.min')
require('../sass/nepali.datepicker.v3.6.min.css')


Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(Vuex)
Vue.use(VueApexCharts)
const router = configureRouter();

Vue.component('apexchart', VueApexCharts)
const nepaliNumber = require('nepali-number')

Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NPR',
        minimumFractionDigits: 0
    });
    return formatter.format(value);
});

Vue.filter('nepaliNumber', function (value) {
    if (value === null) return;
    const formattedText = nepaliNumber.englishToNepaliNumber(value);

    return formattedText !== 'null' || formattedText !== undefined ? formattedText : value;
});

Vue.prototype.$http = axios;
const token = store.getters.token;
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}
const locale = localStorage.getItem('locale') ?  localStorage.getItem('locale') : 'ne'
Vue.prototype.$http.defaults.headers.common['locale'] = locale;


Vue.component('not-found', require('./components/404.vue').default);
const app = new Vue({
    store,
    router,
    mixins: [Layouts],
    i18n,
    el: "#app",
});
