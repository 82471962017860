const Welcome = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Welcome.vue');
const SingleProduct = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Product/SingleProduct.vue');
const StoreSingle = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Shop/StoreSingle.vue');
const Shop = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Shop/Shop.vue');
const Blog = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Blog/Blog.vue');
const BlogSingle = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Blog/SingleBlog.vue');
const ContactUs = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Contact/ContactUs.vue');
const Faq = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Faq/Faq.vue');
const LaunchCountdown = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Countdown.vue');
const TermsAndCondition = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/TermsAndPrivacy/terms_and_conditions.vue');
const PrivacyPolicy = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/TermsAndPrivacy/privacy_policy.vue');

//Auth
const Login = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Authentication/Login.vue');
const Register = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Authentication/Register.vue');
const ResetPasswordEmail = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Authentication/Password/Email.vue');
const ResetPassword = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Authentication/Password/Reset.vue');
const EmailVerify = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Authentication/EmailVerify.vue');
const BecomeVendor = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/BecomeVendor.vue');
const RegisterVendor = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/RegisterVendor.vue');
const EmailVerified = () => import (/* webpackChunkName: "welcome-group" */ '../views/Frontend/Authentication/Verified.vue');

// User Account
const AccountDashboard = () => import (/* webpackChunkName: "account-group" */ '../views/Frontend/Account/Dashboard.vue');
const AccountAddress = () => import (/* webpackChunkName: "account-group" */ '../views/Frontend/Account/Address.vue');
const AccountAddressForm = () => import (/* webpackChunkName: "account-group" */ '../views/Frontend/Account/AddressForm.vue');
const AccountWishlist = () => import (/* webpackChunkName: "account-group" */ '../views/Frontend/Account/Wishlist.vue');
const AccountProfile = () => import (/* webpackChunkName: "account-group" */ '../views/Frontend/Account/Profile.vue');
const AccountSecurity = () => import (/* webpackChunkName: "account-group" */ '../views/Frontend/Account/Security.vue');

// Vendor Account
const VendorDashboard = () => import (/* webpackChunkName: "vendor-group" */ '../views/Frontend/Vendor/Dashboard.vue');
const VendorProduct = () => import (/* webpackChunkName: "vendor-group" */ '../views/Frontend/Vendor/Product.vue');
const VendorProductForm = () => import (/* webpackChunkName: "vendor-group" */ '../views/Frontend/Vendor/ProductForm.vue');
const VendorProfile = () => import (/* webpackChunkName: "vendor-group" */ '../views/Frontend/Vendor/Profile.vue');
const VendorSecurity = () => import (/* webpackChunkName: "vendor-group" */ '../views/Frontend/Vendor/Security.vue');
const VendorStoreProfile = () => import (/* webpackChunkName: "vendor-group" */ '../views/Frontend/Vendor/StoreProfile.vue');

export default [
    {
        path: '/',
        name: 'welcome',
        component: Welcome,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/become-vendor',
        name: 'become-vendor',
        component: BecomeVendor,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/register-vendor',
        name: 'register-vendor',
        component: RegisterVendor,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/password/email',
        name: 'reset-password-email',
        component: ResetPasswordEmail,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/password/reset/:token',
        name: 'password-reset',
        component: ResetPassword,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/email/verify/:id/:token',
        name: 'email-verified',
        component: EmailVerified,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/shop',
        name: 'shop',
        component: Shop,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/product/:uid',
        name: 'single-product',
        component: SingleProduct,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/store/:uid',
        name: 'store-single',
        component: StoreSingle,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/blog',
        name: 'blog',
        component: Blog,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/blog/:uid',
        name: 'blog-single',
        component: BlogSingle,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/terms-and-condition',
        name: 'terms-and-condition',
        component: TermsAndCondition,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/launch-countdown',
        name: 'launch-countdown',
        component: LaunchCountdown,
        meta: {
            requiresVisitor: false,
        }
    },

    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiresVisitor: false,
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            requiresVisitor: false,
        }
    },

    {
        path: '/email/verify',
        name: 'email-verify',
        component: EmailVerify,
        meta: {
            requiresAuth: false,
        }
    },

    // Account
    {
        path: '/account/dashboard',
        name: 'account-dashboard',
        component: AccountDashboard,
        meta: {
            title: 'Dashboard', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
    {
        path: '/account/address',
        name: 'account-address',
        component: AccountAddress,
        meta: {
            title: 'Addresses', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
    {
        path: '/account/address/create',
        name: 'account-address-create',
        component: AccountAddressForm,
        meta: {
            title: 'Address Form', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
    {
        path: '/account/address/:uid/edit',
        name: 'account-address-edit',
        component: AccountAddressForm,
        meta: {
            title: 'Address Form', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
    {
        path: '/account/wishlist',
        name: 'account-wishlist',
        component: AccountWishlist,
        meta: {
            title: 'Wishlist', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
    {
        path: '/account/profile',
        name: 'account-profile',
        component: AccountProfile,
        meta: {
            title: 'Profile', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
    {
        path: '/account/security',
        name: 'account-security',
        component: AccountSecurity,
        meta: {
            title: 'Security', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },

    // Vendor
    {
        path: '/vendor/dashboard',
        name: 'vendor-dashboard',
        component: VendorDashboard,
        meta: {
            title: 'Dashboard', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
    {
        path: '/vendor/product',
        name: 'vendor-product',
        component: VendorProduct,
        meta: {
            title: 'Addresses', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
    {
        path: '/vendor/product/create',
        name: 'vendor-product-create',
        component: VendorProductForm,
        meta: {
            title: 'Address Form', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
    {
        path: '/vendor/product/:uid/edit',
        name: 'vendor-product-edit',
        component: VendorProductForm,
        meta: {
            title: 'Address Form', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
    {
        path: '/vendor/profile',
        name: 'vendor-profile',
        component: VendorProfile,
        meta: {
            title: 'Profile', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
    {
        path: '/vendor/security',
        name: 'vendor-security',
        component: VendorSecurity,
        meta: {
            title: 'Security', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
    {
        path: '/vendor/store-profile',
        name: 'vendor-store-profile',
        component: VendorStoreProfile,
        meta: {
            title: 'Store Profile', rule: true,
            requiresAuth: true,
            requiresVerified: true,
        }
    },
];

