import axios from "axios";
import i18n from "../../i18n";

const state = {
    locale: i18n.locale,
    logo: '',
    user: JSON.parse(localStorage.getItem('user')),
    isChatOpen: false,
    showFullLoader: false,
    currentMonth: null,
    currentFiscalYear: null,
    client_id: null,
    isStoreApproved: null,
    fiscalYears: [],
    navigations: [
        {
            icon: "mdi mdi-view-dashboard",
            id: 0,
            label: "menuitems.dashboard.text",
            name: "dashboard",
            navigation: "/admin/dashboard"
        },
        {
            icon: "mdi mdi-package-variant",
            id: 1,
            label: "menuitems.products.text",
            name: "products",
            navigation: "/admin/products"
        },
        {
            icon: "mdi mdi-storefront",
            id: 2,
            label: "menuitems.stores.text",
            name: "stores",
            navigation: "/admin/stores"
        },
        {
            icon: "mdi mdi-bullhorn",
            id: 3,
            label: "menuitems.campaigns.text",
            name: "stores",
            navigation: "/admin/campaigns"
        },
        {
            icon: "mdi mdi-layers-outline",
            id: 4,
            label: "menuitems.categories.text",
            name: "categories",
            navigation: "/admin/categories"
        },
        {
            icon: "mdi mdi-post",
            id: 5,
            label: "menuitems.posts.text",
            name: "posts",
            navigation: "/admin/posts"
        },
        {
            icon: "mdi mdi-frequently-asked-questions",
            id: 7,
            label: "menuitems.faq.text",
            name: "faq",
            navigation: "/admin/faq"
        },
        {
            icon: "mdi mdi-account-group",
            id: 6,
            label: "menuitems.users.text",
            name: "users",
            navigation: "/admin/",
            subItems: [
                {
                    icon: "mdi mdi-account-group",
                    id: 6,
                    label: "menuitems.vendors.text",
                    name: "vendors",
                    navigation: "/admin/vendors"
                },
                {
                    icon: "mdi mdi-account-group",
                    id: 6,
                    label: "menuitems.consumers.text",
                    name: "consumers",
                    navigation: "/admin/consumers"
                },
                {
                    icon: "mdi mdi-account-group",
                    id: 6,
                    label: "menuitems.staffs.text",
                    name: "users",
                    navigation: "/admin/users"
                },
            ]
        },

    ],
    months: [
        {label: 'साउन', value: 4},
        {label: 'भदौ', value: 5},
        {label: 'असोज', value: 6},
        {label: 'कार्तिक', value: 7},
        {label: 'मंसिर', value: 8},
        {label: 'पुष', value: 9},
        {label: 'माघ', value: 10},
        {label: 'फागुन', value: 11},
        {label: 'चैत', value: 12},
        {label: 'वैशाख', value: 1},
        {label: 'जेठ', value: 2},
        {label: 'असार', value: 3}
    ],
    percentage: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 85, 100],
}
const mutations = {
    locale(state, value) {
        state.locale = value
    },
    user(state, value) {
        state.user = value
    },
    isChatOpen(state, value) {
        state.isChatOpen = value
    },
    showFullLoader(state, value) {
        state.showFullLoader = value
    },
    currentMonth(state, value) {
        state.currentMonth = value
    },
    currentFiscalYear(state, value) {
        state.currentFiscalYear = value
    },
    client_id(state, value) {
        state.client_id = value
    },
    logo(state, value) {
        state.logo = value
    },
    months(state, value) {
        state.months = value
    },
    percentage(state, value) {
        state.percentage = value
    },
    navigations(state, value) {
        state.navigations.push(value)
    },
    fiscalYears(state, value) {
        state.fiscalYears = value
    },
    isStoreApproved(state, value) {
        state.isStoreApproved = value
    },
}
const getters = {
    locale: state => state.locale,
    user: state => state.user,
    isChatOpen: state => state.isChatOpen,
    showFullLoader: state => state.showFullLoader,
    currentMonth: state => state.currentMonth,
    currentFiscalYear: state => state.currentFiscalYear,
    client_id: state => state.client_id,
    logo: state => state.logo,
    months: state => state.months,
    percentage: state => state.percentage,
    navigations: state => state.navigations,
    fiscalYears: state => state.fiscalYears,
    isStoreApproved: state => state.isStoreApproved,
}
const actions = {
    updateSecurity(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/profile/security/${data.id}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    welcome() {
        return new Promise((resolve, reject) => {
            axios.get(`welcome`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    productSingle(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(`category/product/${data.uid}`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    storeSingle(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(`shop/store/${data.uid}`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAllProducts(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`shop`, {params: params})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getBlogs(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(`blog`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getBlogSingle(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(`blog/${data}`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    storeContactUs(context, data) {
        return new Promise((resolve, reject) => {
            axios.post(`contact-us`, data)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getPublicFaqs(context) {
        return new Promise((resolve, reject) => {
            axios.get('faq-list')
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
