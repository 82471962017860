import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getAddresses(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`address`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createAddress(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`address/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeAddress(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`address`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editAddress(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`address/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateAddress(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`address/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteAddress(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`address/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
