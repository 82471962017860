import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getFaqs(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('faq', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createFaq(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`faq/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeFaq(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`faq`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editFaq(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`faq/${data.uid}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateFaq(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                console.log(data)
                axios.post(`faq/${data.uid}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteFaq(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`faq/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
