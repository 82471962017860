import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getRegisterStores(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`register-store`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createRegisterStore(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`register-store/create`, {params: params})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    storeRegisterStore(context, data) {
        return new Promise((resolve, reject) => {
            axios.post(`register-store`, data)
                .then(response => {
                    const {token, user} = response.data
                    const login_activity_id = response.data.login_activity_id
                    localStorage.setItem('token', token)
                    localStorage.setItem('accessToken', token)
                    localStorage.setItem('login_activity_id', login_activity_id)
                    localStorage.setItem('user', JSON.stringify(user))
                    context.commit('token', token)
                    context.commit('accessToken', token)
                    context.commit('login_activity_id', login_activity_id)
                    context.commit('user', user)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    showRegisterStore(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`register-store/${data.uid}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editRegisterStore(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`register-store/${data.uid}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateRegisterStore(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`register-store/${data.uid}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteRegisterStore(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`register-store/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
