import axios from "axios";

const state = {
    store: null,
    product: null,
}
const mutations = {
    storeId(state, payload) {
        state.storeId = payload
    },
    productId(state, payload) {
        state.productId = payload
    }
}
const getters = {
    storeId(state) {
        return state.storeId
    },
    productId(state) {
        return state.productId
    },
}

const actions = {
    getStores(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`store`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createStore(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`store/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeStore(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`store`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showStore(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`store/${data.uid}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editStore(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`store/${data.uid}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateStore(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`store/${data.uid}?_method=PUT`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteStore(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`store/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
